<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item >首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>达人管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
                <span class="title">昵称：</span>
                <el-input size="small" placeholder="请输入昵称" v-model="param.name" @keyup.enter.native="searchList()">
                    <i slot="suffix" class="el-icon-search search-bottom" @click="searchList()"/>
                </el-input>
<!--                <el-button type="primary" size="small" @click="showAdd()" style="margin-left: 10px;">添加达人</el-button>-->
            </div>

            <!--------------------用户表格--------------------->
<!--            姓名、昵称、接单量、在线状态、 服务时间、地区-->
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="昵称"></el-table-column>
                <el-table-column prop="fullname" label="姓名"></el-table-column>
                <el-table-column prop="orders_count" label="接单量"></el-table-column>
                <el-table-column prop="online" label="在线状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.online == 1" style="color: #67c23a;">在线</div>
                        <div v-if="scope.row.online == 0" style="color: red;">离线</div>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="a" label="服务时间"></el-table-column>-->
                <el-table-column prop="area_id" label="区域位置">
                    <template slot-scope="scope">
                        <span>{{ showArea(scope.row.area_id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="open_account" label="结算开户">
                    <template slot-scope="scope">
                        <span v-if="">{{ scope.row.open_account == 1 ? '是' : '否' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="230">
                    <template slot-scope="scope" >
                        <el-button type="text" size="small" @click="editItem(scope.row)">编辑</el-button>
<!--                        <el-divider direction="vertical"></el-divider>-->
<!--                        <el-button type="text" size="small" @click="showSet(scope.row)">设置开户信息</el-button>-->
<!--                        <el-divider direction="vertical"></el-divider>-->
<!--                        <el-button type="text" size="small" @click="showAccount(scope.row)">开户入驻</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!--编辑对话框-->
            <el-dialog :title="isEdit ?  '编辑达人' : '添加达人'" :visible.sync="addDialog" width="592px"  append-to-body  @close="closeDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: scroll;">
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
                        <el-form-item label="签约商家：" prop="arena_id">
                            <el-select v-model="form.arena_id" size="small" filterable clearable>
                                <el-option v-for="(item, index) in arenaArr" :key="index" :label="item.fullname" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="昵称：" prop="nickname">
                            <el-input size="small" v-model="form.nickname" placeholder="请输入" maxlength="7" show-word-limit ></el-input>
                        </el-form-item>
                        <el-form-item label="姓名：" prop="fullname">
                            <el-input size="small" v-model="form.fullname" placeholder="" maxlength="7" show-word-limit :disabled="isEdit"></el-input>
                        </el-form-item>
                        <el-form-item label="性别：" prop="sex">
                            <el-radio v-model="form.sex" label="1">男</el-radio>
                            <el-radio v-model="form.sex" label="0">女</el-radio>
                        </el-form-item>
                        <el-form-item label="手机号：" prop="cell_phone">
                            <el-input size="small" v-model="form.cell_phone" placeholder=""  maxlength="11" show-word-limit :disabled="isEdit"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号：">
                            <el-input size="small" v-model="form.cert_no" placeholder=""  maxlength="18" show-word-limit :disabled="isEdit"></el-input>
                        </el-form-item>
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-date-picker size="small" v-model="form.birthday" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="星座：" prop="star_sign">
                            <el-select v-model="form.star_sign" placeholder="请选择" size="small">
                                <el-option v-for="item in starSignArr" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身高(cm)：" prop="height">
                            <el-input size="small" v-model="form.height" placeholder="请输入" maxlength="3" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="体重(kg)：" prop="weight">
                            <el-input size="small" v-model="form.weight" placeholder="请输入" maxlength="3" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="区域位置：" prop="area_id">
                            <el-cascader size="small" v-model="form.area_id" :options="areaTree"
                                         :props="{emitPath:false, value:'id', label:'name',children:'children', expandTrigger: 'hover' }"
                            ></el-cascader>
                        </el-form-item>
                        <div >
                            <a href="https://lbs.qq.com/getPoint/" target="_blank" style="text-decoration: none;color: #409EFF;margin-left: 150px;">点击去拾取坐标</a>
                        </div>
                        <el-form-item label="常驻地点经纬度：" prop="point">
                            <el-input size="small" v-model="form.point" placeholder="拾取坐标复制到此处" @input="changeMessage($event)"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="可约开始时间：" prop="work_start">-->
<!--                            <el-time-select size="small" v-model="form.work_start" :picker-options="{start: '00:00',step: '00:01', end: '23:59'}" placeholder="开始时间">-->
<!--                            </el-time-select>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="可约结束时间：" prop="work_end">-->
<!--                            <el-time-select size="small" v-model="form.work_end" :picker-options="{start: '00:00', step: '00:01', end: '23:59', minTime: form.work_start}" placeholder="结束时间">-->
<!--                            </el-time-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="可约时间：" prop="work_time">
                            <el-time-picker v-model="form.work_time" format="HH:mm" value-format="HH:mm" size="small" is-range style="width: 320px"
                                            range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @input="changeMessage($event)">
                            </el-time-picker>
                        </el-form-item>
<!--                        <el-form-item label="价格(元)：" prop="price">-->
<!--                            <el-input size="small" v-model="form.price" placeholder="请输入"  maxlength="9" show-word-limit ></el-input>-->
<!--                        </el-form-item>-->
                        <el-form-item label="分成(1-100)：" prop="profit">
                            <el-input-number size="small" v-model="form.profit" :min="1" :max="100" class="num"></el-input-number>
                        </el-form-item>
                        <el-form-item label="技能：" prop="itemIds">
                            <el-select v-model="form.itemIds" placeholder="请选择" size="small" multiple @change="changeItem()">
                                <el-option v-for="item in itemArr" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <br/>
                        </el-form-item>
                        <div class="skill-list">
                            <div class="skill" v-for="(item, index) in items" :key="index">
                                <div class="text">{{ item.item }}：</div>
                                <el-input v-model="item.price" placeholder="价格" size="small" style="width: 150px;margin-left: 10px;" maxlength="9" show-word-limit></el-input>
                                <el-input-number  placeholder="最低消费时长" controls-position="right" size="small" v-model="item.limit_hour" :min="1" :max="100" class="hour" style="margin-left: 10px;"></el-input-number>
                            </div>
                        </div>
                        <el-form-item label="头像：" prop="avatar">
                            <div style="display: flex;">
                                <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                           :http-request="httpRequest" :before-upload="beforeAvatarUpload">
                                    <img v-if="form.avatar" :src="form.avatar" class="avatar" alt="">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <div class="tip-box" style="margin-left: 10px;">
                                    <div>图片大小限制10M</div>
                                    <div>仅支持png、jpg、jpeg格式图片</div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="生活照：" prop="photoArr">
                            <div class="photo-box">
                                <div class="photo" v-for="(item, index) in form.photoArr" :key="index">
                                    <img v-if="item.type == 0" :src="item.url" alt=""/>
                                    <video v-if="item.type == 1" controlslist="nodownload" disablePictureInPicture muted  x5-video-player-type="h5" x5-video-player-fullscreen
                                           x5-video-orientation="portraint" x-webkit-airplay playsinline x5-playsinline controls webkit-playsinline="true"
                                           ref="videoRef">
                                        <source type="video/mp4" :src="item.url">
                                    </video>
                                    <div class="remove-box">
                                        <i class="el-icon-delete" @click="removePhoto(index)"></i>
                                    </div>
                                </div>
                                <div class="upload-input"  v-if="Number(form.photoArr.length) < 10">
                                    <i class="el-icon-plus avatar-uploader-icon" style="position: absolute;"></i>
                                    <span class="tip">图片/视频最多10张</span>
                                    <input type="file" placeholder="点击上传" class="file-input" @change="uploadFile($event)">
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="个性签名：">
                            <el-input type="textarea" placeholder="请输入" v-model="form.signature" maxlength="40" show-word-limit style="width: 320px;"
                                      :autosize="{ minRows: 4, maxRows: 4}">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="addDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
            <!-------------图片预览对话框----------------->
            <el-dialog title="图片预览" :visible.sync="previewPicVisible" width="700px">
                <!--图片宽高都小于容器时垂直、水平居中
                图片宽高都大于容器时保持宽高比将width或height充满容器-->
                <div style="width:100%;height:400px;display: table-cell;vertical-align: middle;text-align: center;">
                    <img :src="previewPath" alt="" style="max-width: 100%;max-height: 100%;display: block;margin: 0 auto;"/>
                </div>
            </el-dialog>

            <!--开户信息对话框-->
            <el-dialog :title="'开户设置-'+setForm.fullname" :visible.sync="setDialog" width="592px"  append-to-body  @close="closeSetDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: auto;">
                    <el-form :model="setForm" :rules="setRules" ref="setRuleForm" label-width="150px">
                        <el-form-item label="身份证号：" prop="cert_no">
                            {{ setForm.cert_no }}
<!--                            <el-input size="small" v-model="setForm.cert_no" placeholder="请输入" maxlength="18" show-word-limit ></el-input>-->
                        </el-form-item>
                        <el-form-item label="身份证有效期类型：" prop="cert_validity_type">
                            <el-radio-group v-model="setForm.cert_validity_type"  @change="changeType">
                                <el-radio :label="0">非长期有效</el-radio>
                                <el-radio :label="1">长期有效</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="身份证有效期：" prop="time" v-if="setForm.cert_validity_type == 0">
                            <el-date-picker size="small" v-model="setForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @input="changeDate">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="开户行区域编码：" prop="code1">
                            <el-cascader size="small" v-model="setForm.code1" :options="areaArr"
                                         :props="{value:'id', label:'name',children:'children', expandTrigger: 'hover' }"
                                         @change="changeCode"></el-cascader><br/>
                            <div style="line-height: 20px;font-size: 13px;">{{ showCode }}</div>
                        </el-form-item>
                        <el-form-item label="银行卡号：" prop="bank_card_no">
                            <el-input size="small" v-model="setForm.bank_card_no" placeholder="请输入" ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="setDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitSetForm('setRuleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
            <!--开户入驻对话框-->
            <el-dialog :title="'开户入驻-'+currentObj.fullname" :visible.sync="accountDialog" width="592px"  append-to-body  @close="closeAccountDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: auto;">
                    <el-form  label-width="150px">
                        <el-form-item label="身份证号：">
                            {{ currentObj.cert_no }}
                        </el-form-item>
                        <el-form-item label="身份证有效期类型：">
                            <span v-if="currentObj.cert_validity_type == 0">非长期有效</span>
                            <span v-if="currentObj.cert_validity_type == 1">长期有效</span>
                        </el-form-item>
                        <el-form-item label="身份证有效期：" v-if="currentObj.cert_validity_type == 0">
                            {{ currentObj.cert_begin_date ? (currentObj.start + '至' + currentObj.end) : '' }}
                        </el-form-item>
                        <el-form-item label="开卡银行编码：">
                            {{currentObj.bank_prince_code ? (currentObj.bank_prince_code + '/' + currentObj.bank_city_code) : '' }}
                        </el-form-item>
                        <el-form-item label="银行卡号：" >
                            {{ currentObj.bank_card_no }}
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="accountDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitAccountForm()" :loading="btnLoading">开户入驻</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "list",
    data() {
        var checkPrice = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
                callback(new Error('金额格式错误'))
            } else {
                callback()
            }
        }
        var validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^1[3-9]\d{9}$/.test(value)) {
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        var validatorHeight = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/.test(value)) {
                callback(new Error('格式错误'))
            } else {
                callback()
            }
        }
        var validatorId = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}[\dXx]$/.test(value)) {
                callback(new Error('身份证号错误'))
            } else {
                callback()
            }
        }
        return {
            param: {//请求参数
                type: '',//级别
                name: ''//级别
            },
            list: [],//表格列表
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
            itemArr: [],//陪玩项目
            loading: false,//表格加载loading

            isEdit: false,//是否编辑
            addDialog: false,//添加弹窗
            inputVisible: false,
            inputValue: '',//标签
            starSignArr: ['水瓶座','双鱼座','白羊座','金牛座','双子座','巨蟹座','狮子座','处女座','天秤座','天蝎座','射手座','摩羯座',],//星座
            form: {//编辑（级别）、昵称、头像、⽣活照
                arena_id: '',//签约商家
                fullname: '',
                nickname: '',
                sex: '0',//性别：0女 1男
                cell_phone: '',
                cert_no: '',
                birthday: '',
                star_sign: '',//星座
                height: '',//身高
                weight: '',//体重
                area_id: '',
                //price: '',//价格
                profit: undefined,//分成
                itemIds: [],//技能
                avatar: '',//头像
                photoArr: [],//生活照
                signature: '',//个性签名
                //work_start: '',
                //work_end: '',
                work_time: '',
                point: ''
            },
            rules: {// 表单校验规则对象
                arena_id: [
                    {required: false, message: '请选择', trigger: 'change'},
                ],
                fullname: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                nickname: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                sex: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                cell_phone: [
                    {required:true,validator: validatorPhone,trigger:'blur'},
                ],
                cert_no: [
                    {required:false,validator: validatorId,trigger:'blur'},
                ],
                birthday: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                area_id: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                star_sign: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                height: [
                    {required:true,validator: validatorHeight, trigger:'blur'},
                ],
                weight: [
                    {required:true,validator: validatorHeight, trigger:'blur'},
                ],
                experienced: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                method_ids: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                level_id: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                // price: [
                //     {required:true, validator: checkPrice,trigger:'blur'},
                // ],
                profit: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                tagArr: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                itemIds: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                avatar: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                photoArr: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                // work_start: [
                //     {required: true, message: '请选择', trigger: 'change'},
                // ],
                // work_end: [
                //     {required: true, message: '请选择', trigger: 'change'},
                // ],
                work_time: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                point: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            items: [],//提交的技能
            input: '',
            hour: undefined,
            btnLoading: false,//按钮状态
            // 图片预览路径
            previewPath: '',
            // 控制图片预览对话框的显示与隐藏
            previewPicVisible: false,
            areaTree: [],//区域位置选项
            //开户信息
            setDialog: false,//开户弹窗
            setForm: {//开户设置表单提交内容
                cert_no: '',
                cert_validity_type: 0,
                time: '',
                code1: '',
                bank_card_no: '',
            },
            showCode: '',//开户行区域编码
            setRules: {// 表单校验规则对象
                cert_no: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                cert_validity_type: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                time: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                code1: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                bank_card_no: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            areaArr: [],//区域树(开户行区域编码选项)
            accountDialog: false,//开户入驻弹窗
            currentObj: {},//当前对象
            arenaArr: [],//签约商家选项
        }
    },
    mounted () {
        this.getAreaTree();//查区域树
        this.getList();//查列表
        //this.getMethod();  //查打法
       // this.getLevel();  //查等级
        this.getPlayItem();  //查技能
        this.getAreaCode();//查省市区域
        this.getArena();//查商家
    },
    methods: {
        //查商家
        async getArena() {
            let { data: res } = await this.$http.get('/admin/api/arena/list',)
            if (res.code == 200) {
                if(res.data) {
                    this.arenaArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //input失去焦点
        inputBlur(value, type){
            var reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
            if(type = 0) {//校验身高体重
                if(!reg.test(value)){
                    return this.$message.error('请输入整数或最多两位小数')
                }
            } else {//校验金额
                if(!reg.test(value)){
                    return this.$message.error('金额格式错误')
                }
            }

        },
        // 强制更新文本框的值
        changeMessage() {
            this.$forceUpdate()
        },
        //打开添加弹窗
        showAdd() {
            this.addDialog = true
            this.$forceUpdate()
        },
        //查区域位置选项
        async getAreaTree() {
            let { data: res } = await this.$http.get('/admin/api/area/treeList', )
            if (res.code == 200) {
                if(res.data) {
                    this.areaTree = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //查开户行区域编码选项
        async getAreaCode() {
            let { data: res } = await this.$http.get('/admin/api/area/treeList?level=2', )
            if (res.code == 200) {
                if(res.data) {
                    this.areaArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //切换身份证有效期类型
        changeType() {
           if(this.setForm.cert_validity_type == 1) {
               this.setForm.time = []
           }
            this.$forceUpdate()
        },
        //从第一页开始重新加载列表
        searchList() {
            this.query.page = 1
            this.query.number = 10
            this.getList();
        },
        //查列表
        async getList() {
            this.loading = true;
            let data = {
                status: 1,
                nickname: this.param.name,
                level_id: this.param.type,
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/assistant/pageList', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.page = 1
            this.query.number = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
        //查技能
        async getPlayItem() {
            let data = {
                type: 'play_item',
                pageType: 0,//0不分页 1分页
            }
            let res = await this.$http.get('/admin/api/get/dict', { params: data})
            if (res.data.code == 200) {
                if(res.data.data) {
                    this.itemArr = res.data.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //修改技能
        changeItem() {
            let idLen = _.cloneDeep(this.form.itemIds.length)
            let itemLen = _.cloneDeep(this.items.length)
            if(idLen > itemLen)  {//新增就按id查出新增的对象，赋值到items中
                let curId = this.form.itemIds[this.form.itemIds.length-1];
                let obj = null;
                let findIndex = (this.itemArr || []).findIndex((item) => item.id == curId);
                if(findIndex != -1) {
                    obj = this.itemArr[findIndex];
                }
                if(obj) {
                    this.items.push({dict_id:obj.id, item: obj.name, price: '',  limit_hour:undefined})
                }
            } else {//删除就找到删除的哪一个，items中删掉
                let idArr =  this.form.itemIds;
                for(let i=0; i<this.items.length; i++) {
                    if(idArr.join(',').indexOf(this.items[i].dict_id) > -1) {//找到了
                    } else {//没找到，要删除的id
                        let findIndex = (this.items || []).findIndex((item) => item.id == this.items[i].dict_id);
                        this.items.splice(findIndex, 1);
                    }
                }
            }
        },
        //添加/编辑
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                const height = this.form.height;
                if (valid) {
                    // let priceCopy = _.cloneDeep(this.form.price)
                    // if(priceCopy > 999999.99) {
                    //     return this.$message.error('价格不能超过999999.99')
                    // }
                    let item = [];
                    let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
                    for(let i=0; i<this.items.length; i++) {
                        if(this.items[i].limit_hour == undefined || this.items[i].limit_hour == null || this.items[i].limit_hour == '') {
                            return this.$message.error('请输入技能最低时长')
                        }
                        if(!reg.test(this.items[i].price)){
                            return this.$message.error('技能价格格式错误')
                        }
                        let priceCopy = _.cloneDeep(this.items[i].price)
                        if(priceCopy > 999999.99) {
                            return this.$message.error('技能价格不能超过999999.99')
                        }
                        item.push({dict_id:this.items[i].dict_id, item:this.items[i].item,price:parseInt((priceCopy * 100).toFixed(0)),limit_hour:this.items[i].limit_hour })
                    }
                    this.btnLoading = true;
                    let time = this.form.work_time;
                    let param = {
                        arena_id: this.form.arena_id,
                        nickname: this.form.nickname.trim(),
                       // fullname: this.form.fullname.trim(),
                        sex: this.form.sex,
                        cell_phone: this.form.cell_phone.trim(),
                      //  cert_no: this.form.cert_no.trim(),
                        birthday: this.form.birthday,
                        star_sign: this.form.star_sign,
                        height: this.form.height.trim(),
                        weight: this.form.weight.trim(),
                        area_id: this.form.area_id,
                       // price: parseInt((priceCopy * 100).toFixed(0)),
                        profit: this.form.profit,
                        items: item,
                        avatar: this.form.avatar,
                        signature: this.form.signature,
                        //work_start: this.form.work_start,
                        //work_end: this.form.work_end,
                        work_start: time[0],
                        work_end: time[1],
                        longitude: this.form.point.trim().split(",")[1],
                        latitude: this.form.point.trim().split(",")[0],
                    }
                    let res = {};
                    if (this.isEdit) {
                        param.id = this.form.id;
                        let arr = [];
                        for(let i=0; i<this.form.photoArr.length; i++) {
                            arr.push(this.form.photoArr[i].url);
                        }
                        param.life_photos = arr;
                        res = await this.$http.post('/admin/api/edit/assistant', param)
                    } else {
                        let arr = [];
                        for(let i=0; i<this.form.photoArr.length; i++) {
                            arr.push(this.form.photoArr[i].url);
                        }
                        param.life_photos = arr;
                        res = await this.$http.post('/admin/api/add/assistant', param)
                    }
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeDialog() {
            if (this.form.id) {
                delete this.form.id;
            }
            this.btnLoading = false;
            this.$refs['ruleForm'].resetFields();
            this.$forceUpdate();
            this.form.photoArr = [];
            this.addDialog = false;
            this.isEdit = false;
            this.items = []
            this.form.signature = '';
            this.form.work_time = '';
        },
        //编辑
        async editItem(rows) {
            this.isEdit = true;
            this.$nextTick(() => {
                this.form = _.cloneDeep(rows);
                this.form.work_time = [rows.work_start, rows.work_end]
                this.form.birthday = rows.birthday.substring(0, 10);
                //this.form.price = Number(rows.price) / 100;
                this.form.photoArr = []
                this.form.itemIds = []
                for(let i=0; i<rows.items.length; i++) {
                    this.form.itemIds.push(rows.items[i].dict_id)
                    this.items.push({dict_id: rows.items[i].dict_id, item: rows.items[i].item, price: Number(rows.items[i].price) / 100, limit_hour: rows.items[i].limit_hour})
                }
                if(rows.latitude && rows.longitude) {
                    this.$set(this.form, 'point',  rows.latitude+','+rows.longitude);
                }
                this.showPhotos(rows);
                this.$forceUpdate();
            })
            this.addDialog = true; //打开对话框
        },
        async showPhotos(row) {
            let res = await this.$http.get('/admin/api/get/assistant/photo', { params: {id: row.id}})
            if (res.data.code == 200) {
                if(res.data.data) {
                    let list = res.data.data
                    let newArr = []
                    for(let i=0; i<list.length; i++) {
                        let type = 0;//0图片1视频
                        if(list[i].photo_url.indexOf('mp4') != -1) {
                            type = 1;
                        }
                        newArr.push({type: type, url: list[i].photo_url});
                    }
                    this.form.photoArr = newArr;
                    this.$forceUpdate();
                }
            } else {
                this.$message.error(res.message)
            }
        },
        changeDate() {
            this.$forceUpdate()
        },
        changeCode() {
            let str = '';
            if(this.setForm.code1) {
                str = this.setForm.code1.join(' / ');
            }
            this.showCode = str;
        },

        //打开设置开户信息
        showSet(rows) {
            this.$nextTick(() => {
                this.setForm = _.cloneDeep(rows);
                if(rows.bank_prince_code &&  rows.bank_city_code) {
                    this.$set(this.setForm, 'code1', [rows.bank_prince_code, rows.bank_city_code]);
                    this.changeCode();
                } else {
                    this.$set(this.setForm, 'code1', '');
                }
                if(rows.cert_validity_type == 0) {
                    if(rows.cert_begin_date && rows.cert_end_date) {
                        let start = rows.cert_begin_date;
                        let star1 = start.slice(0, 4) + '-' + start.slice(4, start.length);
                        let res = star1.slice(0, 7) + '-' + star1.slice(7, star1.length);
                        let end = rows.cert_end_date;
                        let end1 = end.slice(0, 4) + '-' + end.slice(4, end.length);
                        let res1 = end1.slice(0, 7) + '-' + end1.slice(7, end1.length);
                        this.$set(this.setForm, 'time', [res, res1]);
                    } else {
                        this.$set(this.setForm, 'time', '');
                    }
                } else {
                    this.$set(this.setForm, 'time', '');
                }
            })
            this.setDialog = true; //打开对话框
        },
        //添加/编辑
        submitSetForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if(this.setForm.cert_validity_type == 0) {
                        if(!this.setForm.time) {
                            return  this.$message.error('请选择身份证有效期')
                        }
                    }
                    if(!this.setForm.code1) {
                        return  this.$message.error('请选择开户行区域编码')
                    }
                    this.btnLoading = true;
                    let param = {
                        id: this.setForm.id,
                        cert_no: this.setForm.cert_no,
                        cert_validity_type: this.setForm.cert_validity_type,
                        cert_begin_date: this.setForm.cert_validity_type == 0 ? this.setForm.time[0].split("-").join('') : '',
                        cert_end_date: this.setForm.cert_validity_type == 0 ? this.setForm.time[1].split("-").join('') : '',
                        bank_prince_code: this.setForm.code1[0],
                        bank_city_code: this.setForm.code1[1],
                        bank_card_no: this.setForm.bank_card_no,
                    }
                    let res = await this.$http.post('/admin/api/assistant/setBankInfo', param)
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeSetDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeSetDialog() {
            this.btnLoading = false;
            this.$refs['setRuleForm'].resetFields();
            this.$set(this.setForm, 'time', '');
            this.setDialog = false;
            this.showCode = '';
        },
        //上传图片
        async httpRequest(http) {
            let file = http.file;
            let formData = new FormData();
            formData.append('file', file)
            let {data: res} = await this.$http({
                url: '/admin/api/upload',
                data: formData,
                headers: {
                    'Content-Type': "multipart/form-data; charset=UTF-8",
                },
                method:'post'
            });
            if (res.code === 200) {
                this.form.avatar = res.data.url;
            } else {
                this.$message.error(res.message)
            }
        },
        //上传前校验文件类型
        beforeAvatarUpload(file) {
            let type = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (type === 'png' || type === 'jpg' || type === 'jpeg') {
                if(file.size > 1024 * 1000 * 10) {
                    this.$message.error('图片不允许超过10M');
                    return false;
                }
            } else {
                this.$message.error('仅支持png、jpg、jpeg格式图片！');
                return false;
            }
        },
        //选中就上传
        async uploadFile(event) {
            let fileType = 0;
            let file = event.target.files[0]
            // 判断文件类型   如果是jpg、png 则支持上传。否则 中断并提示
            let type = file.type;
            if (file.size > 1024 * 1024 * 50) {
                this.$message.error('文件大小不能超过50M')
                event.target.value = '';
                return;
            }
            if(type.indexOf('video') !== -1){
                fileType = 1;
                if (file.type !== 'video/mp4') {
                    this.$message.error('视频仅支持mp4格式')
                    event.target.value = '';
                    return
                }
                event.target.value = ''
            } else if(type.indexOf('image') !== -1) {
                if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                    this.$message.error('图片仅支持png/jpg/jpeg格式')
                    event.target.value = '';
                    return
                }
                event.target.value = '';
            } else {
                return this.$message.error('文件格式不正确')
            }
            let formData = new FormData();
            formData.append('file', file)
            let {data: res} = await this.$http({
                url: '/admin/api/upload',
                data: formData,
                headers: {
                    'Content-Type': "multipart/form-data; charset=UTF-8",
                },
                method:'post'
            });
            if (res.code === 200) {
                this.form.photoArr.push({type: fileType, url: res.data.url})
                this.$forceUpdate();
            } else {
                this.$message.error(res.message)
            }
        },
        //删除生活照
        removePhoto(index) {
            this.form.photoArr.splice(index, 1);
            this.$forceUpdate();
        },
        //显示区域名称
        showArea(areaId) {
            let str = '';
            let obj = this.fundNode(this.areaTree, areaId);
            if(obj) {
                str = obj.name;
            }
            return str;
        },
        //按id递归查出对象
        fundNode(tree, id) {
            var stark = []
            stark = stark.concat(tree)
            while (stark.length) {
                var temp = stark.shift()
                if (temp.children) {
                    stark = stark.concat(temp.children)
                }
                if (temp.id === id) {
                    return temp
                }
            }
        },
        //打开设置开户信息
        showAccount(rows) {
            this.$nextTick(() => {
                this.currentObj = rows
                if(rows.cert_begin_date && rows.cert_end_date) {
                    let start = rows.cert_begin_date;
                    let star1 = start.slice(0, 4) + '-' + start.slice(4, start.length);
                    let res = star1.slice(0, 7) + '-' + star1.slice(7, star1.length);
                    let end = rows.cert_end_date;
                    let end1 = end.slice(0, 4) + '-' + end.slice(4, end.length);
                    let res1 = end1.slice(0, 7) + '-' + end1.slice(7, end1.length);
                    this.currentObj.start = res;
                    this.currentObj.end = res1;
                }
            })
            this.accountDialog = true; //打开对话框
        },
        //添加/编辑
        async submitAccountForm() {
            if(!this.currentObj.bank_card_no) {
                return  this.$message.error('请先设置开户信息')
            }
            this.btnLoading = true;
            let res = await this.$http.post('/admin/api/assistant/openAccount', { id: this.currentObj.id})
            if (res.data.code === 200) {
                this.btnLoading = false;
                this.$message.success('操作成功！')
                this.closeAccountDialog();
                await this.searchList();
            } else {
                this.btnLoading = false;
                this.$message.error(res.data.message)
            }
        },
        //关闭对话框
        closeAccountDialog() {
            this.btnLoading = false;
            this.currentObj = {}
            this.$forceUpdate();
            this.accountDialog = false;
        },
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {border: 1px dashed #d9d9d9;border-radius: 6px;cursor: pointer;position: relative;overflow: hidden;}
.avatar-uploader .el-upload:hover {border-color: #409EFF;}
.avatar-uploader-icon {border: 1px dashed #c0ccda;font-size: 28px;color: #8c939d;width: 110px;height: 78px;line-height: 80px;text-align: center;background-color: #fbfdff;}
.avatar-uploader-icon:hover {border: 1px dashed #409EFF;}
.avatar {width: 110px;height: 78px;display: block;object-fit: cover;}
.tip-box {margin-left: 10px;}
.tip-box div {height: 25px;}

.photo-box {display: flex;flex-wrap: wrap;}
.photo {width: 112px;height: 80px;margin-right: 6px;margin-bottom: 6px; background-color: #eee;position: relative;}
.upload-input {width: 112px;height: 80px;margin-right: 6px;margin-bottom: 6px;position: relative;background-color: #fbfdff;}
.file-input {width: 112px;height: 80px;position: absolute;left: 0;top: 0;opacity: 0;}
.photo img{width: 100%;height: 100%;object-fit: cover;}
.photo video {width: 100%;height: 100%;object-fit: cover;}
.upload-input .tip {top: 44px;font-size: 12px;left: 5px;position: absolute;}

.photo:hover .remove-box {display: block;}
.remove-box {position: absolute;width: 112px;height: 80px;background-color: rgba(0, 0, 0, .5);color: #fff;top: 0;cursor: pointer;display: none;}
.remove-box .el-icon-delete {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);font-size: 20px;}
.el-tag + .el-tag {margin-left: 10px;}
.button-new-tag {margin-left: 10px;height: 32px;line-height: 30px;padding-top: 0;padding-bottom: 0;}
.input-new-tag {width: 90px;margin-left: 10px;vertical-align: bottom;}

.skill-list {margin-bottom: 30px;margin-top: -5px;}
.skill {display: flex;align-items: center; width: 470px;margin-bottom: 12px;}
.skill .text{width: 140px;text-align: right;color: #262626;}
</style>
<style>
.num .el-input {width: 130px !important;}
    /*.skill .el-input {margin-left: 10px;}*/
.hour .el-input {width: 150px;}
.skill .el-input-number--small{width: 150px;}
</style>
